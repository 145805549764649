@font-face {
  font-family: "FoxCavalier";
  src: url("../assets/FoxCavalier.woff2") format("woff2"),
    url("../assets/FoxCavalier.woff") format("woff"),
    url("../assets/FoxCavalier.ttf") format("truetype");
}

.standardShadow {
  -webkit-box-shadow: 0px 4px 12px -4px rgba(92, 92, 92, 1);
  -moz-box-shadow: 0px 4px 12px -4px rgba(92, 92, 92, 1);
  box-shadow: 0px 4px 12px -4px rgba(92, 92, 92, 1);
}

.standardHolder {
  z-index: 11;
  background-color: transparent;
  font-family: "FoxCavalier";
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
}

@media (max-width: 714px) {
  .standardHolder {
    font-size: 0.8rem;
  }
  .nameSection {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.nameSection {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.starfieldHolder {
  z-index: -1;
  position: absolute;
  top: 5px;
  left: 5px;
  bottom: 5px;
  right: 5px;
}
.nameHolder {
  text-overflow: wrap;
  z-index: 10;
  display: inline-block;
  font-family: "FoxCavalier";
  font-size: 12rem;
  position: relative;
  color: #fff;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.continuousSlider {
  font-family: "FoxCavalier";
}
.sliderControl {
  display: flex;
  align-items: center;
}
.sliderIconLeft {
  margin-right: 20px;
}
.sliderIconRight {
  margin-left: 20px;
}
.warpSlider {
  width: 250px;
}

@media (max-width: 714px) {
  .warpSlider {
    width: 180px;
  }
}
.coming-soon {
  font-size: 1.2rem;
}
.socialBtn {
  height: 50px;
  width: 50px;
  color: #3f51b5;
  font-size: 1.5rem;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialBtn:hover {
  transform: translateY(-3.5px);
  -webkit-box-shadow: 0px 7.5px 14px -4px rgba(143, 141, 143, 1);
  -moz-box-shadow: 0px 7.5px 14px -4px rgba(143, 141, 143, 1);
  box-shadow: 0px 7.5px 14px -4px rgba(143, 141, 143, 1);
}

.centeredFlex {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.controlSection {
  z-index: 10;
  margin-top: -150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1380px) {
  .nameHolder {
    font-size: 10rem;
  }
  .controlSection {
    z-index: 10;
    margin-top: -150px;
  }
}
@media screen and (max-width: 1220px) {
  .nameHolder {
    font-size: 9rem;
  }
  .controlSection {
    z-index: 10;
    margin-top: -100px;
  }
}
@media screen and (max-width: 1072px) {
  .nameHolder {
    font-size: 8rem;
    margin-bottom: -50px;
  }
  .controlSection {
    z-index: 10;
    margin-top: -50px;
  }
}
@media screen and (max-width: 954px) {
  .nameHolder {
    font-size: 7rem;
    margin-bottom: -90px;
  }
  .controlSection {
    z-index: 10;
    margin-top: 0px;
  }
}
@media screen and (max-width: 842px) {
  .nameHolder {
    font-size: 6rem;
    margin-bottom: -70px;
  }
}
@media screen and (max-width: 713.99px) {
  .nameHolder {
    font-size: 5rem;
    margin-bottom: -45px;
  }
}
@media screen and (max-width: 592px) {
  .nameHolder {
    font-size: 4rem;
    margin-bottom: -30px;
  }
}
@media screen and (max-width: 472px) {
  .nameHolder {
    font-size: 3rem;
    margin-bottom: -10px;
  }
}
@media screen and (max-width: 360px) {
  .nameHolder {
    font-size: 2.5rem;
    margin-bottom: 0px;
  }
}
.knockout {
  display: inline-block;
  overflow: hidden;
  text-align: left;
  position: relative;
  color: transparent;
  border: 0.5em solid #fff;
  white-space: nowrap;
}
.knockoutSVG {
  fill: rgb(255, 255, 255);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.sliderHolder {
  z-index: 11;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-family: "FoxCavalier";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0;
}
@media (max-width: 714px) {
  .sliderHolder {
    padding: 5px 10px;
    border-radius: 6px;
  }
  .continuousSlider {
    font-size: 0.8rem;
  }
}